import React from 'react';
import { Container, Spinner, Row, Col, Alert } from 'react-bootstrap';

import Banner from './Banner';
import ScheduleRow from './ScheduleRow';

const Schedule = (props) => {

  if ( !Array.isArray(props.schedule) || !props.schedule.length ) {
    return (
      <>
        <Banner
          headline="Loading..."
        />
        <Container>
          <Spinner animation="border" />
        </Container>
      </>
    );
  }

  // Long timezone (e.g. Europe/Dublin)

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const shortTimezone = 'Your time'

  let lastTimestamp = new Date(1);

  return (
    <>
      <Banner
        headline="SCA Webminister Symposium"
        subhead="13-14 March 2021"
      />

      <Container>

        <Row>
          <Col>
          </Col>

          <Col xs={12} sm={10} md={8}>

            <Alert variant="dark">
              The office of the Web Minister has grown over the years and is now provides an essential service to the Society for Creative Anachronism (SCA). The first Web Minister’s Symposium is intended to provide an opportunity for all people interested in the SCA’s digital footprint to exchange ideas and advance our knowledge of best practices for our digital communication.<br /><br />

              Some of our sessions will be highly technical. Other sessions will focus on topics such as effective communication, officer retention, and recruiting. All interested parties are welcome to attend. Your insights and perspective improve our collective understanding of the position of Web Minister as it continues to evolve. 
            </Alert>

            <br />
          </Col>

          <Col>
          </Col>
        
        </Row>


        <Row>
          <Col xs={12} className="text-center">
            Event time is <b>{props.eventTimezone.shortname}</b> (UTC{props.eventTimezone.offset})
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            Your timezone is <b>{timezone}</b>
          </Col>
        </Row>

        { 
          props.schedule.map( ( row, i ) => {

            const outputRow = <ScheduleRow
                                key={i}
                                row={row}
                                index={i}
                                lastTimestamp={lastTimestamp}
                                timezone={timezone}
                                shortTimezone={shortTimezone}
                                eventTimezone={props.eventTimezone}
                                roomnames={props.roomnames}
                              />;

            lastTimestamp = row.when;

            return outputRow;

          })
        }

      </Container>
      
    </>
  );


}

export default Schedule;